
import React, { useState, useEffect } from "react";
// import axios from './Login/axiosConfig'
import './scss/fonts/embedFonts.css'


// test
import { Widget, addResponseMessage, toggleMsgLoader } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import './ds_styles.css'

if (process.env.NODE_ENV === 'development') {
  window.$ipRiskAPI = 'http://localhost:5000'

}

if (process.env.NODE_ENV === 'production') {
  window.$ipRiskAPI = 'https://datasnackapi.uc.r.appspot.com'
}




const Chatbot = () => {
  const [chatWindowOpen, setChatWindowOpen] = useState(true);
  const [allMessages, setAllMessages] = useState([])
  const [clientId, setClientId] = useState("")

  const handleToggle = (open) => {
    setChatWindowOpen((prev) => !prev);
  };



  const handleNewUserMessage = (newMessage) => {
    let tempMessages = [...allMessages];
    tempMessages.push(
      { "sender": "DataSnack Bot", "message": newMessage }
    )
    toggleMsgLoader()
    fetch(`${window.$ipRiskAPI}/api/v1/domainBot`, {
      method: 'POST',
      body: JSON.stringify({
        contentSiloId: "0fd073f9-bbbd-4ed5-86e4-9cce8761618a",
        clientCompanyId: "d8124667-a299-473d-97b2-7bd8b327dd2a",
        messages: tempMessages
      }),
    }).then((res) => res.json())
      .then((data) => {
        toggleMsgLoader()
        addResponseMessage(data.message);

        tempMessages.push(data)
        setAllMessages(tempMessages)
        console.log("result from ds bot", data)

      }).catch(error => {
        toggleMsgLoader()
        console.log("error from bot", error)
        addResponseMessage("There was a problem processing your message. Please try again.");
      });
  }

  useEffect(() => {
    console.log("in use effect")
    let temp = document.getElementById("dataSnackClientId")
    if (temp != null) {
      let id = temp.getAttribute("data-id")
      console.log("id", id)
      setClientId(id)
      if (id === "datasnack") {
        addResponseMessage("Welcome to DataSnack's Live AI Chatbot! Ask us anything and receive both answers to your questions and a DataSnack SnackRisk score.");
      }
    } else {

      addResponseMessage("Welcome to the Live AI Chatbot! Ask us anything and receive both answers to your questions and a SnackRisk score.");


    }


  }, [])


  return (
    <div>
      <Widget
        handleToggle={handleToggle}
        handleNewUserMessage={handleNewUserMessage}
        title="Live AI Chat"
        senderPlaceHolder="Message..."
        subtitle=""
      />
    </div>
  );
}

export default Chatbot;
